import { mobile } from "@g51/hubi-components"
import Markdown from "@g51/hubi-components/components/Markdown"
import RichTextEditor from "@happens-lol/react-rte"
import React, { useMemo } from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import styled, { css } from "styled-components"
import { ArrowRightOutlined } from "@ant-design/icons"
import moment from "moment"
import { CMS_CONFIG } from "."

const SelectProject: React.FC<{
  landingPageConfig: any
  projects: {
    name: string
    slug: string
    start: string
    end: string
    accessStart: string
    accessEnd: string
  }[]
  clientName: string
}> = ({ landingPageConfig, projects, clientName }) => {
  const imprint = useMemo(
    () =>
      RichTextEditor.createValueFromString(
        landingPageConfig.text ?? "",
        "markdown"
      ),
    [landingPageConfig]
  )

  const projectDates: {
    [slug: string]: {
      start: string | null
      end: string | null
      isActive: boolean
    }
  } = useMemo(
    () =>
      projects.reduce((acc, it) => {
        let isActive = false
        const accessStart = it.accessStart ? moment(it.accessStart) : null
        const accessEnd = it.accessEnd ? moment(it.accessEnd) : null

        if (accessStart !== null && accessEnd !== null) {
          const now = moment()
          const startHasPassed = now.isSameOrAfter(accessStart)
          const endHasNotPassed = now.isSameOrBefore(accessEnd)
          isActive = startHasPassed && endHasNotPassed
        }

        return {
          ...acc,
          [it.slug]: {
            start: it.start ? moment(it.start).format("DD.MM.YY") : null,
            end: it.end ? moment(it.end).format("DD.MM.YY") : null,
            isActive,
          },
        }
      }, {}),
    [projects]
  )

  return (
    <Container
      textColor={landingPageConfig.textColor ?? "#000"}
      linkBoxColor={landingPageConfig.linkBoxColor ?? "#DDD"}
      linkTitleColor={landingPageConfig.linkTitleColor ?? "#000"}
      linkDateColor={landingPageConfig.linkDateColor ?? "#555"}
      backgroundColor={landingPageConfig.backgroundColor ?? "#FFF"}
      buttonBackgroundColor={landingPageConfig.buttonBackgroundColor ?? "#555"}
      buttonTextColor={landingPageConfig.buttonTextColor ?? "#FFF"}
      backgroundImage={landingPageConfig.backgroundImage}
      headingFontFamily={landingPageConfig.headingFontData?.fontFamily}
      bodyFontFamily={landingPageConfig.bodyFontData?.fontFamily}
      cardCornerRadius={landingPageConfig.cardCornerRadius ?? 0}
      cardWidth={landingPageConfig.cardWidth ?? 250}
      cardHeight={landingPageConfig.cardHeight ?? 120}
      cardPadding={landingPageConfig.cardPadding ?? 16}
      pageAlign={landingPageConfig.verticalAlign}
      logoAlign={landingPageConfig.alignLogo}
      textAlign={landingPageConfig.alignText}
      cardAlign={landingPageConfig.alignCards}
    >
      <Helmet>
        {!!landingPageConfig.headingFontData && (
          <link
            rel="stylesheet"
            href={landingPageConfig.headingFontData.cssUrl}
          />
        )}

        {!!landingPageConfig.bodyFontData && (
          <link rel="stylesheet" href={landingPageConfig.bodyFontData.cssUrl} />
        )}
      </Helmet>

      {landingPageConfig.logo ? (
        <img
          alt={clientName}
          src={`${CMS_CONFIG.cmsUrl}/images/${landingPageConfig.logo}`}
        />
      ) : (
        <h1>{clientName}</h1>
      )}

      <Markdown
        className="intro"
        value={imprint}
        primaryColor={landingPageConfig.textColor ?? "#000"}
        secondaryColor={landingPageConfig.textColor ?? "#000"}
        editable={false}
        align={landingPageConfig.alignText ?? "left"}
        onChangeAlign={() => {}}
      />

      <div className="list">
        {projects
          .filter((it) => projectDates[it.slug]?.isActive)
          .map((it) => (
            <Link to={`/${it.slug}`} key={it.slug}>
              <div key={it.slug} className="project">
                <h2>{it.name}</h2>

                {projectDates[it.slug].start && projectDates[it.slug].end && (
                  <p>
                    {projectDates[it.slug].start} - {projectDates[it.slug].end}
                  </p>
                )}

                <div className="select-arrow">
                  <ArrowRightOutlined />
                </div>
              </div>
            </Link>
          ))}
      </div>
    </Container>
  )
}

const Container = styled.div<{
  textColor: string
  linkBoxColor: string
  linkTitleColor: string
  linkDateColor: string
  backgroundColor: string
  buttonBackgroundColor: string
  buttonTextColor: string
  backgroundImage?: string
  headingFontFamily?: string
  bodyFontFamily?: string
  sizeBodyNormal?: number
  sizeBodyBig?: number
  sizeHeadingNormal?: number
  sizeHeadingBig?: number
  cardCornerRadius?: number
  cardWidth?: number
  cardHeight?: number
  cardPadding?: number

  pageAlign?: string
  logoAlign?: string
  textAlign?: string
  cardAlign?: string
}>`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.pageAlign === "center" &&
    css`
      justify-content: center;
    `}

  min-width: 100%;
  min-height: 100vh;
  padding: 6rem 16rem;
  background: ${(props) => props.backgroundColor};
  ${(props) =>
    !!props.backgroundImage &&
    css`
      background: url("${CMS_CONFIG.cmsUrl}/images/${props.backgroundImage}")
        no-repeat center center fixed;
      background-size: cover;
    `}

  ${(props) =>
    props.bodyFontFamily &&
    css`
      * {
        font-family: ${props.bodyFontFamily || "sans-serif"};
      }
    `}

  ${(props) =>
    props.headingFontFamily &&
    css`
      h1,
      h2 {
        font-family: ${props.headingFontFamily || "sans-serif"};

        * {
          font-family: ${props.headingFontFamily || "sans-serif"};
        }
      }
    `}

  ${(props) =>
    props.sizeBodyNormal &&
    css`
      font-size: ${props.sizeBodyNormal}px;
    `}

  ${(props) =>
    props.sizeBodyBig &&
    css`
      h3 {
        font-size: ${props.sizeBodyBig}px;
      }
    `}

  ${(props) =>
    props.sizeHeadingNormal &&
    css`
      h2 {
        font-size: ${props.sizeHeadingNormal}px;
      }
    `}

  ${(props) =>
    props.sizeHeadingBig &&
    css`
      h1 {
        font-size: ${props.sizeHeadingBig}px;
      }
    `}

  ${mobile`
    padding: 4rem;
  `}

  img {
    max-width: 300px;
    max-height: 400px;
    margin-bottom: 1.5rem;
  }

  ${(props) =>
    props.logoAlign === "center" &&
    css`
      img,
      > h1 {
        margin: 0 auto;
      }
    `}

  ${(props) =>
    props.logoAlign === "right" &&
    css`
      img,
      > h1 {
        margin: 0 0 0 auto;
      }
    `}

  .intro {
    margin-bottom: 3rem;

    ${(props) =>
      props.cardAlign === "center" &&
      css`
        justify-content: center;
      `}

    ${(props) =>
      props.cardAlign === "right" &&
      css`
        justify-content: flex-end;
      `}
  }

  > h1 {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  a {
    text-decoration: none;
  }

  .list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 0;
    margin-right: -1.5rem;

    ${(props) =>
      props.cardAlign === "center" &&
      css`
        justify-content: center;
      `}

    ${(props) =>
      props.cardAlign === "right" &&
      css`
        justify-content: flex-end;
      `}
  }

  div.project {
    position: relative;
    width: ${(props) => props.cardWidth ?? 250}px;
    height: ${(props) => props.cardHeight ?? 120}px;
    border-radius: ${(props) => props.cardCornerRadius ?? 10}px;
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
    transition: 0.1s box-shadow linear;
    padding: ${(props) => props.cardPadding ?? 16}px;
    background: ${(props) => props.linkBoxColor};

    h2 {
      color: ${(props) => props.linkTitleColor};
      margin: 0;
    }

    p {
      color: ${(props) => props.linkDateColor};
      margin: 4px 0;
      font-size: 0.9em;
    }

    div.select-arrow {
      position: absolute;
      bottom: ${(props) => props.cardPadding ?? 16}px;
      right: ${(props) => props.cardPadding ?? 16}px;
      background: ${(props) => props.buttonBackgroundColor};
      color: ${(props) => props.buttonTextColor};
      padding: 10px 24px;
      border-radius: ${(props) => props.cardCornerRadius ?? 10}px;
      font-size: 20px;
    }

    &:hover {
      box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.09);
    }
  }
`

export default SelectProject
