import React, { useEffect, useMemo } from "react"
import { useHistory, useParams } from "react-router"
import { gql, useQuery } from "@apollo/client"
import { Category, CustomValues } from "@g51/hubi-components"
import ViewBasketTemplate from "@g51/hubi-components/templates/web/ViewBasket"
import {
  ViewBasketQuery,
  ViewBasketQueryVariables,
} from "./types/ViewBasketQuery"
import { imageURL } from "./util/common"
import { CMS_CONFIG } from "."

const VIEW_BASKET_QUERY = gql`
  query ViewBasketQuery($projectId: ID!, $nfcCode: String!) {
    basket(projectId: $projectId, nfcCode: $nfcCode) {
      id
      downloadables {
        id
        name
        categoryId
        description
        previewImage
      }
      project {
        id
        defaultDownloadableIcon
        downloadables {
          categories {
            category {
              id
              name
            }
            downloadables {
              id
              name
              description
              previewImage
            }
          }
        }
      }
    }
  }
`

interface ViewBasketProps {
  values: CustomValues
  projectId: string
  projectName: string
  analyticsKey: string
}

const downloadBundle = (url: string) => {
  const a = document.createElement("a")
  a.href = url
  a.target = "_blank"
  a.download = "iBasket.zip"

  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url)
      a.removeEventListener("click", clickHandler)
    }, 300)
  }

  a.addEventListener("click", clickHandler, false)
  a.click()
}

const ViewBasket: React.FC<ViewBasketProps> = ({
  projectId,
  values,
  projectName,
  analyticsKey,
}) => {
  const history = useHistory()
  const { projectSlug } = useParams<{ projectSlug: string }>()
  const { basketCode } = useParams<{ basketCode: string }>()

  useEffect(() => {
    gtag("config", analyticsKey, {
      category: "website",
      client_storage: "none",
      send_page_view: false,
      user_id: basketCode,
    })

    gtag("event", "page_view", {
      category: "website",
      page_title: "Basket",
      user_id: basketCode,
      page_path: window.location.pathname,
      page_location: window.location.host,
      send_to: analyticsKey,
      project: projectName,
    })
  }, [])

  const viewBasketQuery = useQuery<ViewBasketQuery, ViewBasketQueryVariables>(
    VIEW_BASKET_QUERY,
    {
      variables: {
        projectId,
        nfcCode: basketCode,
      },
    }
  )

  const includedDownloadables: { [id: string]: boolean } =
    viewBasketQuery.data?.basket.downloadables.reduce(
      (acc, it) => ({ ...acc, [it.id]: true }),
      {}
    ) ?? {}

  const categories = useMemo(
    () =>
      (viewBasketQuery.data?.basket.project.downloadables.categories ?? [])
        .map<Category>((it) => ({
          id: it.category.id,
          name: it.category.name,
          downloadables: it.downloadables
            .filter((it) => includedDownloadables[it.id])
            .map(({ id, name, description, previewImage }) => ({
              id,
              name,
              selected: true,
              description,
              previewImage,
            })),
        }))
        .filter((cat) => cat.downloadables.length > 0),
    [viewBasketQuery.data]
  )

  return (
    <ViewBasketTemplate
      value={values}
      categories={categories}
      fallbackIcon={
        viewBasketQuery.data?.basket.project.defaultDownloadableIcon ??
        undefined
      }
      makeDownloadableURL={(id) =>
        `${CMS_CONFIG.cmsUrl}/files/downloadables/${id}`
      }
      onQuit={() => {
        gtag("event", "website_logout", {
          category: "website",
          user_id: basketCode,
          event_label: "Ausgeloggt",
          send_to: analyticsKey,
          project: projectName,
        })

        history.push(`/${projectSlug}`)
      }}
      onDownloadOne={(name, cat) => {
        gtag("event", "website_download_one", {
          category: "website",
          user_id: basketCode,
          event_label: "Downloadable heruntergeladen",
          send_to: analyticsKey,
          project: projectName,
          downloadable: name,
          downloadableCategory: cat,
          downloadableAddType: "single",
        })
      }}
      onDownloadAll={() => {
        gtag("event", "website_download_all", {
          category: "website",
          user_id: basketCode,
          event_label: "Alle Downloadables heruntergeladen",
          send_to: analyticsKey,
          project: projectName,
        })

        downloadBundle(
          `${CMS_CONFIG.cmsUrl}/files/bundles/${viewBasketQuery.data?.basket.project.id}/${viewBasketQuery.data?.basket.id}`
        )
      }}
      makeImageURL={imageURL}
    />
  )
}

export default ViewBasket
