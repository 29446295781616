import React from "react"
import styled, { keyframes } from "styled-components"
import { CMS_CONFIG } from ".."

export const imageURL = (file: string) => `${CMS_CONFIG.cmsUrl}/images/${file}`

export const parseGA4Key = (key: string) =>
  key.includes(":") ? key.split(":")[0] : key

const loadingAnim = keyframes`
  0%, 80%, 100% { 
    transform: scale(0);
  }

  40% { 
    transform: scale(1.0);
  }
`

const LoadingContainer = styled.div<{ color?: string }>`
  margin: 0 auto 0;
  width: 100px;
  text-align: center;
  display: flex;
  justify-content: space-between;

  > div {
    width: 25px;
    height: 25px;
    background-color: ${(props) => props.color ?? "#888"};

    border-radius: 100%;
    display: inline-block;
    animation: ${loadingAnim} 1.4s infinite ease-in-out both;
  }

  .bounce1 {
    animation-delay: -0.32s;
  }

  .bounce2 {
    animation-delay: -0.16s;
  }
`

export const Loading = () => (
  <LoadingContainer>
    <div className="bounce1" />
    <div className="bounce2" />
    <div className="bounce3" />
  </LoadingContainer>
)

const FullscreenLoadingContainer = styled.div`
  width: 100vw;
  height: calc(100vh - 2rem);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FullscreenLoading = () => {
  return (
    <FullscreenLoadingContainer>
      <Loading />
    </FullscreenLoadingContainer>
  )
}
