import React, { useState, useMemo } from "react"
import { Global } from "./styles"
import styled, { css } from "styled-components"
import { Normalize } from "styled-normalize"
import { gql, useQuery } from "@apollo/client"
import { Switch, Route } from "react-router"
import SelectProject from "./SelectProject"
import Project from "./Project"
import { InstanceQuery } from "./types/InstanceQuery"
import RichTextEditor from "@happens-lol/react-rte"
import Modal from "@g51/hubi-components/components/Modal"
import Markdown from "@g51/hubi-components/components/Markdown"
import { Helmet } from "react-helmet"
import { CMS_CONFIG } from "."

const INSTANCE_QUERY = gql`
  query InstanceQuery {
    instance {
      clientName
      logo
      strings {
        imprint
        dataPrivacyNotice
        dark
      }
      customValues
      projectsCompact {
        projects {
          id
          name
          slug
          start
          end
          accessStart
          accessEnd
        }
      }
    }
  }
`

const App = () => {
  const instanceQuery = useQuery<InstanceQuery>(INSTANCE_QUERY)
  const [showImprint, setShowImprint] = useState(false)
  const [showDataPrivacy, setShowDataPrivacy] = useState(false)

  const imprint = useMemo(
    () =>
      RichTextEditor.createValueFromString(
        instanceQuery.data?.instance?.strings?.imprint ?? "",
        "markdown"
      ),
    [instanceQuery.data]
  )

  const dataPrivacyNotice = useMemo(
    () =>
      RichTextEditor.createValueFromString(
        instanceQuery.data?.instance?.strings?.dataPrivacyNotice ?? "",
        "markdown"
      ),
    [instanceQuery.data]
  )

  return (
    <>
      <Normalize />
      <Global />

      <Helmet>
        <title>{`${CMS_CONFIG.name} HUB-i`}</title>
      </Helmet>

      <Container>
        <Modal isShown={showImprint} onHide={() => setShowImprint(false)}>
          <Markdown
            value={imprint}
            primaryColor="#333"
            secondaryColor="#333"
            editable={false}
            align="left"
            onChangeAlign={() => {}}
          />
        </Modal>

        <Modal
          isShown={showDataPrivacy}
          onHide={() => setShowDataPrivacy(false)}
        >
          <Markdown
            value={dataPrivacyNotice}
            primaryColor="#333"
            secondaryColor="#333"
            editable={false}
            align="left"
            onChangeAlign={() => {}}
          />
        </Modal>

        <Switch>
          <Route path="/" exact>
            <SelectProject
              landingPageConfig={
                instanceQuery.data?.instance.customValues.landingPage ?? {}
              }
              clientName={instanceQuery.data?.instance.clientName ?? ""}
              projects={
                instanceQuery.data?.instance.projectsCompact.projects ?? []
              }
            />
          </Route>

          <Route path="/:projectSlug">
            <Project />
          </Route>
        </Switch>

        <Footer dark={instanceQuery.data?.instance.strings.dark ?? false}>
          <button onClick={() => setShowImprint(true)}>
            {instanceQuery.data?.instance.customValues.imprintLink ??
              "Impressum"}
          </button>
          <button onClick={() => setShowDataPrivacy(true)}>
            {instanceQuery.data?.instance.customValues.privacyLink ??
              "Datenschutz"}
          </button>
        </Footer>
      </Container>
    </>
  )
}

const Container = styled.div`
  width: 100%;
`

export const Footer = styled.div<{ dark: boolean }>`
  width: 85%;
  height: 3rem;
  margin: -3rem auto 0 auto;

  display: flex;
  justify-content: center;

  button {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    padding: 1.5em 0.5em;
    font-size: 14px;
    color: #555;

    ${({ dark }) =>
      dark &&
      css`
        color: #fff;
      `}

    &:focus {
      outline: none;
    }

    &::-moz-focus-inner {
      border: none;
    }
  }
`

export default App
