import { createGlobalStyle } from "styled-components"

export const Global = createGlobalStyle`
  html, body {
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;
    overflow-y: auto;
  }

  body {
    background: #fff;

    font-family: -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, 'Helvetica Neue', Arial,
      'Noto Sans', sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    
    h1, h2 {
      font-family: -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, 'Helvetica Neue', Arial,
      'Noto Sans', sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    }

    button {
      line-height: normal;
    }

    #root {
      width: 100%;
    }
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }
`
