import React from "react"
import { getDefaultEditable, ProjectPage } from "./util/page"
import { useEditable, deserializeCustomValues } from "@g51/hubi-components"
import {
  useParams,
  Switch,
  useRouteMatch,
  Route,
  useHistory,
} from "react-router"
import { gql, useQuery } from "@apollo/client"
import CodeEntry from "./CodeEntry"
import ViewBasket from "./ViewBasket"
import { ProjectQuery, ProjectQueryVariables } from "./types/ProjectQuery"
import { FullscreenLoading, parseGA4Key } from "./util/common"
import { Helmet } from "react-helmet"
import moment from "moment"
import { CMS_CONFIG } from "."

const PROJECT_QUERY = gql`
  query ProjectQuery($projectSlug: String!) {
    siteData(projectSlug: $projectSlug) {
      project {
        id
        name
        logo
        subLogo
        logoAlignment
        subLogoAlignment
        analyticsKey
        showSubLogo
        customValues
        accessStart
        accessEnd
      }
    }
  }
`

const Project = () => {
  const match = useRouteMatch()
  const history = useHistory()
  const { projectSlug } = useParams<{ projectSlug: string }>()
  const projectQuery = useQuery<ProjectQuery, ProjectQueryVariables>(
    PROJECT_QUERY,
    {
      variables: { projectSlug },
      onCompleted: (data) => {
        const { project: it } = data.siteData
        const accessStart = it.accessStart ? moment(it.accessStart) : null
        const accessEnd = it.accessEnd ? moment(it.accessEnd) : null

        if (accessStart === null || accessEnd === null) {
          history.replace("/")
          return
        }

        const now = moment()
        const startHasPassed = now.isSameOrAfter(accessStart)
        const endHasNotPassed = now.isSameOrBefore(accessEnd)
        if (!startHasPassed || !endHasNotPassed) {
          history.replace("/")
          return
        }
      },
    }
  )

  const editable = useEditable(
    getDefaultEditable(projectQuery.data?.siteData?.project?.customValues ?? {})
  )

  const customValues = !projectQuery.data
    ? {}
    : deserializeCustomValues(projectQuery.data.siteData.project.customValues)

  return (
    <>
      <Helmet>
        <title>
          {`${CMS_CONFIG.name} HUB-i | ${projectQuery.data?.siteData.project.name}`}
        </title>

        {!!customValues?.["font-heading"] && (
          <link
            rel="stylesheet"
            href={customValues["font-heading"]?.["css-url"]}
          />
        )}

        {!!customValues?.["font-body"] && (
          <link
            rel="stylesheet"
            href={customValues["font-body"]?.["css-url"]}
          />
        )}
      </Helmet>

      {!projectQuery.data ? (
        <FullscreenLoading />
      ) : (
        <Switch>
          <Route path={match.path} exact>
            <ProjectPage
              editable={editable}
              projectQuery={projectQuery.data}
              page="code-entry"
              headingFontFamily={`${
                customValues?.["font-heading"]?.family ?? ""
              }`}
              bodyFontFamily={`${customValues?.["font-body"]?.family ?? ""}`}
            >
              <CodeEntry
                projectId={projectQuery.data.siteData.project.id}
                projectName={projectQuery.data.siteData.project.name}
                analyticsKey={parseGA4Key(
                  projectQuery.data.siteData.project.analyticsKey
                )}
                values={customValues}
              />
            </ProjectPage>
          </Route>

          <Route path={`${match.path}/basket/:basketCode`} exact>
            <ProjectPage
              editable={editable}
              projectQuery={projectQuery.data}
              page="view-basket"
              headingFontFamily={`${
                customValues?.["font-heading"]?.family ?? ""
              }`}
              bodyFontFamily={`${customValues?.["font-body"]?.family ?? ""}`}
              sizeBodyNormal={customValues["font-body"]?.["size-normal"]}
              sizeBodyBig={customValues["font-body"]?.["size-big"]}
              sizeHeadingNormal={customValues["font-heading"]?.["size-normal"]}
              sizeHeadingBig={customValues["font-heading"]?.["size-big"]}
            >
              <ViewBasket
                projectName={projectQuery.data.siteData.project.name}
                analyticsKey={parseGA4Key(
                  projectQuery.data.siteData.project.analyticsKey
                )}
                projectId={projectQuery.data.siteData.project.id}
                values={customValues}
              />
            </ProjectPage>
          </Route>
        </Switch>
      )}
    </>
  )
}

export default Project
