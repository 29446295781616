import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import {
  HttpLink,
  InMemoryCache,
  ApolloProvider,
  ApolloClient,
} from "@apollo/client"
import App from "./App"

export let CMS_CONFIG = {
  hostnames: ["localhost"],
  cmsUrl: process.env.REACT_APP_CMS_URL as string,
  name: "Staging",
}

const INSTANCE_CMS_CONFIG = {
  staging: {
    hostnames: ["localhost", "staging.g51.dev"],
    cmsUrl: process.env.REACT_APP_CMS_URL as string,
    name: "Staging",
  },
  expopartner: {
    hostnames: ["expopartner.g51.dev"],
    cmsUrl: "https://cms.expopartner.g51.dev",
    name: "Expopartner",
  },
  expopartner2: {
    hostnames: ["expopartner2.g51.dev"],
    cmsUrl: "https://cms.expopartner2.g51.dev",
    name: "Expopartner 2",
  },
  pfizer: {
    hostnames: ["pfizer.g51.dev"],
    cmsUrl: "https://cms.pfizer.g51.dev",
    name: "Pfizer",
  },
  merck: {
    hostnames: ["merck.g51.dev"],
    cmsUrl: "https://cms.merck.g51.dev",
    name: "Merck",
  },
  sanofi: {
    hostnames: ["sanofi.g51.dev"],
    cmsUrl: "https://cms.sanofi.g51.dev",
    name: "Sanofi",
  },
  alexion: {
    hostnames: ["alexion.g51.dev"],
    cmsUrl: "https://cms.alexion.g51.dev",
    name: "Alexion",
  },
  incyte: {
    hostnames: ["incyte.g51.dev"],
    cmsUrl: "https://cms.incyte.g51.dev",
    name: "Incyte",
  },
  takeda: {
    hostnames: ["takeda.g51.dev"],
    cmsUrl: "https://cms.takeda.g51.dev",
    name: "Takeda",
  },
  janssen: {
    hostnames: ["janssen.g51.dev"],
    cmsUrl: "https://cms.janssen.g51.dev",
    name: "Janssen",
  },
}

for (let it of Object.values(INSTANCE_CMS_CONFIG)) {
  const matches = it.hostnames.filter((host) =>
    window.location.hostname.startsWith(host)
  )

  if (matches.length > 0) {
    console.log(
      `selected ${it.name} (${it.cmsUrl}) for hostname ${window.location.hostname}`
    )
    CMS_CONFIG = it
    break
  }
}

export const cmsURL = `${CMS_CONFIG.cmsUrl}/query`
const httpLink = new HttpLink({ uri: cmsURL })

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink,
})

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById("root")
)
