import React from "react"
import styled, { css } from "styled-components"
import {
  DefaultBaseConfig,
  CustomValues,
  mobile,
  UseEditable,
  getThemeProps,
} from "@g51/hubi-components"
import { ProjectQuery } from "../types/ProjectQuery"
import { imageURL } from "./common"
import { CMS_CONFIG } from ".."

export const getDefaultEditable = (values: CustomValues) => ({
  baseConfig: DefaultBaseConfig,
  editable: false,
  outer: values,
  setOuter: () => {},
  elements: {},
})

const LogoContainer = styled.div`
  width: 1024px;
  max-width: 90%;
  display: flex;
  justify-content: space-between;

  .right,
  .left,
  .center {
    flex: 1;
    display: flex;
  }

  .left {
    justify-content: flex-start;
  }

  .center {
    justify-content: center;
  }

  .right {
    justify-content: flex-end;
  }

  .right .sub:not(:first-child) {
    margin-left: 2rem;
  }

  .left .main:not(:last-child) {
    margin-right: 2rem;
  }

  .center .main:not(:last-child) {
    margin-right: 2rem;
  }

  img {
    object-fit: contain;
    max-height: 400px;
    max-width: 300px;
  }

  ${mobile`
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .sub, .main {
      margin-left: 0!important;
      margin-right: 0!important;
      max-width: 90%;
    }

    .right, .left, .center {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    img {
      margin-bottom: 2.5rem;
    }
  `}
`

export const ProjectPage: React.FC<{
  editable: UseEditable
  page: string
  projectQuery?: ProjectQuery
  headingFontFamily: string | null
  bodyFontFamily: string | null
  sizeBodyNormal?: number
  sizeBodyBig?: number
  sizeHeadingNormal?: number
  sizeHeadingBig?: number
}> = ({
  editable,
  page,
  projectQuery,
  children,
  headingFontFamily,
  bodyFontFamily,
  sizeBodyNormal,
  sizeBodyBig,
  sizeHeadingNormal,
  sizeHeadingBig,
}) => (
  <WebsiteContainer
    {...getThemeProps(editable, "web", page)}
    headingFontFamily={headingFontFamily}
    bodyFontFamily={bodyFontFamily}
    sizeBodyNormal={sizeBodyNormal}
    sizeBodyBig={sizeBodyBig}
    sizeHeadingNormal={sizeHeadingNormal}
    sizeHeadingBig={sizeHeadingBig}
  >
    <LogoContainer>
      {["left", "center", "right"].map((pos) => (
        <div className={pos} key={pos}>
          {projectQuery?.siteData.project.logoAlignment === pos && (
            <img
              src={imageURL(projectQuery.siteData.project.logo)}
              alt="Logo"
              className="main"
            />
          )}

          {projectQuery?.siteData.project.showSubLogo &&
            projectQuery?.siteData.project.subLogoAlignment === pos && (
              <img
                src={imageURL(projectQuery.siteData.project.subLogo!!)}
                alt="Logo"
                className="sub"
              />
            )}
        </div>
      ))}
    </LogoContainer>

    <div className="container">{children}</div>
  </WebsiteContainer>
)

export const WebsiteContainer = styled.div<{
  backgroundColor: string
  backgroundImage: string | null
  hasContainer: boolean
  hasContainerShadow: boolean
  containerBorderRadius: number
  containerBackgroundColor: string
  containerBackgroundImage: string | null
  headingFontFamily: string | null
  bodyFontFamily: string | null
  pageAlign: any
  sizeBodyNormal?: number
  sizeBodyBig?: number
  sizeHeadingNormal?: number
  sizeHeadingBig?: number
}>`
  ${(props) =>
    props.bodyFontFamily &&
    css`
      * {
        font-family: ${props.bodyFontFamily || "sans-serif"};
      }
    `}

  ${(props) =>
    props.headingFontFamily &&
    css`
      h1,
      h2 {
        * {
          font-family: ${props.headingFontFamily || "sans-serif"};
        }
      }
    `}

  .DraftEditor-root span {
    font-size: ${(props) => props.sizeBodyNormal || 16}px;
  }

  .DraftEditor-root h3 span {
    font-size: ${(props) => props.sizeBodyBig || 22}px;
  }

  .DraftEditor-root h2 span {
    font-size: ${(props) => props.sizeHeadingNormal || 26}px;
  }

  .DraftEditor-root h1 span {
    font-size: ${(props) => props.sizeHeadingBig || 32}px;
  }

  .category {
    font-size: ${(props) => props.sizeBodyBig || 22}px;
    padding: 18px 16px;
  }

  .container .downloadables {
    .info {
      padding-right: 12px;
    }

    .item .meta {
      font-size: ${(props) => props.sizeBodyNormal || 16}px;

      .desc {
        font-size: 0.95em;
      }
    }

    a.download-link {
      flex-shrink: 0;
      position: absolute;
      right: 20px;
    }
  }

  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  background-color: ${(props) => props.backgroundColor};

  ${mobile`
    padding: 0.5rem;
  `}

  ${(props) =>
    props.pageAlign === "left" &&
    css`
      align-items: flex-start;
    `}

  ${(props) =>
    props.pageAlign === "right" &&
    css`
      align-items: flex-end;
    `}

  ${(props) =>
    props.backgroundImage &&
    css`
      background-position: center center;
      background-size: cover;
      background-image: url(${CMS_CONFIG.cmsUrl}/images/${props.backgroundImage});
    `}

  .container {
    border: 1px solid #666;
    width: 1024px;
    min-height: 768px;
    max-width: 90%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    margin: 2rem;
    background-color: ${(props) => props.containerBackgroundColor};
    border-radius: ${(props) => props.containerBorderRadius}px;
    flex-direction: column;

    ${(props) =>
      props.pageAlign === "left" &&
      css`
        margin-left: 0;
        margin-right: 4rem;
      `}

    ${(props) =>
      props.pageAlign === "right" &&
      css`
        margin-right: 0;
        margin-left: 4rem;
      `}

    ${mobile`
      padding: 1rem;
      margin: 0.5rem;
      max-width: calc(100% - 1rem);
    `}

    > div {
      flex: 1;
    }

    ${(props) =>
      props.hasContainerShadow &&
      css`
        box-shadow: 0px 0px 30px 16px rgba(0, 0, 0, 0.1);
      `}

    ${(props) =>
      props.containerBackgroundImage &&
      css`
        background-position: center center;
        background-size: cover;
        background-image: url(${CMS_CONFIG.cmsUrl}/images/${props.containerBackgroundImage});
      `}

    ${(props) =>
      !props.hasContainer &&
      css`
        border-color: transparent !important;
        background: transparent !important;
        box-shadow: none !important;
        padding: 3rem 0;
        margin-top: 0 !important;
      `}
  }
`
