import React, { useState, useEffect } from "react"
import { CustomValues } from "@g51/hubi-components"
import { gql, useLazyQuery } from "@apollo/client"
import CodeEntryTemplate, {
  CodeState,
} from "@g51/hubi-components/templates/web/CodeEntry"
import { GetBasketQuery, GetBasketQueryVariables } from "./types/GetBasketQuery"
import { imageURL } from "./util/common"
import { useHistory, useParams } from "react-router"
import { v4 as uuid } from "uuid"

const GET_BASKET_QUERY = gql`
  query GetBasketQuery($projectId: ID!, $nfcCode: String!) {
    basket(projectId: $projectId, nfcCode: $nfcCode) {
      id
    }
  }
`

interface CodeEntryProps {
  values: CustomValues
  projectId: string
  projectName: string
  analyticsKey: string
}

const CodeEntry: React.FC<CodeEntryProps> = ({
  values,
  projectId,
  projectName,
  analyticsKey,
}) => {
  useEffect(() => {
    const id = uuid()

    gtag("config", analyticsKey, {
      client_storage: "none",
      send_page_view: false,
      user_id: id,
      category: "website",
      custom_map: { category: "website" },
    })

    gtag("event", "page_view", {
      category: "website",
      page_title: "Code Eingabe",
      page_path: window.location.pathname,
      page_location: window.location.host,
      send_to: analyticsKey,
      project: projectName,
    })
  }, [])

  const history = useHistory()
  const { projectSlug } = useParams<{ projectSlug: string }>()

  const [code, setCode] = useState("")
  const [codeState, setCodeState] = useState(CodeState.Idle)
  const [checkedCode, setCheckedCode] = useState("")

  const [getBasket] = useLazyQuery<GetBasketQuery, GetBasketQueryVariables>(
    GET_BASKET_QUERY,
    {
      onCompleted: () => {
        gtag("event", "website_login", {
          user_id: code,
          category: "website",
          event_label: "Eingeloggt",
          send_to: analyticsKey,
          project: projectName,
        })

        history.push(`/${projectSlug}/basket/${code}`)
      },
      onError: () => setCodeState(CodeState.Invalid),
    }
  )

  useEffect(() => {
    if (code.length !== 6 || code === checkedCode) return

    setCheckedCode(code)
    getBasket({ variables: { projectId, nfcCode: code } })
  }, [code])

  return (
    <CodeEntryTemplate
      codeState={codeState}
      onCodeChanged={(c) => setCode(c.toUpperCase())}
      value={values}
      makeImageURL={imageURL}
    />
  )
}

export default CodeEntry
